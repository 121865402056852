import Network from 'lib/api/network';
import useClientState from 'composables/client-state';

const { getLocalStorage, setLocalStorage } = useClientState();

export default {
  namespaced: true,

  state() {
    return {
      allConfigs: [],
      stats: {},
      comments: {},
      fetchStatus: { configs: false, stats: false, comments: false },
      settings: {
        selectedConfigIds: [],
        showAllConfigs: true,
      },
    };
  },

  getters: {
    showAllConfigs(state) {
      // The modal should prevent users from setting selectedConfigIds to an empty array, but just in case
      return state.settings.showAllConfigs || !state.settings.selectedConfigIds.length;
    },

    configs(state, getters) {
      if (getters.showAllConfigs) return state.allConfigs;
      return state.allConfigs.filter(config => state.settings.selectedConfigIds.includes(config.id));
    },

    configIdsParam(_state, getters) {
      // getters.configs will always be a subset of allConfigs, which represents all the configs visible to the user
      // As such, do not make any calls to a feedback_aggregation_... endpoint with ids that do not come from here
      return getters.configs.map(c => c.id).join('-');
    },
  },

  mutations: {
    updateFetchStatus(state, { key, value }) {
      state.fetchStatus[key] = value;
    },
  },

  actions: {
    fetchInitialData({ state, dispatch, rootState }, { useDefaultSettings }) {
      dispatch('fetchConfigs')
        .then((anyViewableConfigs) => {
          // If the results of fetchConfigs found nothing, just stop. We won't be rendering anything.
          if (!anyViewableConfigs) return;
          // First determine if the user has any settings in localStorage for this module
          // and, crucially, for this team (if they're a super admin they might be viewing other teams)
          let settings;
          const teamIdFromLocalStorage = parseInt(getLocalStorage('teamId'));
          const teamIdFromState = rootState.currentTeam.id;
          if (useDefaultSettings) {
            settings = state.settings;
          } else if (teamIdFromLocalStorage !== teamIdFromState) {
            // Either the first time they're loading this module or viewing a different team.
            // In either event, start with the default values and put them in localStorage accordingly.
            settings = state.settings;
            setLocalStorage('teamId', teamIdFromState);
          } else {
            // This means the user is loading the module for the team they most recently looked at.
            // Pull their settings from localStorage or just use the defaults in state.
            settings = JSON.parse(getLocalStorage('introsFeedbackSettings')) || state.settings;
          }
          dispatch('applySettingsAndFetchData', { settings, isInitialLoad: true });
        })
        .catch(() => { dispatch('setErrorToast', 'Error loading Intros programs', { root: true }); });
    },

    fetchConfigs({ commit }) {
      return new Promise((resolve, reject) => {
        commit('updateFetchStatus', { key: 'configs', value: false });
        Network.get('/coffee_feedbacks/viewable_configurations', {
          success: (data) => {
            commit('update', { module: 'reportingIntrosFeedback', key: 'allConfigs', value: data }, { root: true });
            window.mixpanel.track('Intros Feedback — Loaded Viewable Configs', { numConfigs: data.length, path: window.location.pathname });
            resolve(data.length > 0);
          },
          error: () => { reject(); },
          complete: () => { commit('updateFetchStatus', { key: 'configs', value: true }); },
        });
      });
    },

    fetchStats({ getters, commit }) {
      return new Promise(() => {
        commit('updateFetchStatus', { key: 'stats', value: false });
        Network.get(
          `/coffee_feedbacks/aggregation_stats?config_ids=${getters.configIdsParam}`,
          {
            success: (data) => {
              commit('update', { module: 'reportingIntrosFeedback', key: 'stats', value: data }, { root: true });
              window.mixpanel.track('Intros Feedback — Loaded Stats', { stats: JSON.stringify(data) });
            },
            complete: () => { commit('updateFetchStatus', { key: 'stats', value: true }); },
          },
        );
      });
    },

    fetchComments({ getters, commit }, { pageNum }) {
      return new Promise(() => {
        commit('updateFetchStatus', { key: 'comments', value: false });
        Network.get(
          `/coffee_feedbacks/aggregation_comments?config_ids=${getters.configIdsParam}&page=${pageNum}`,
          {
            success: (data) => {
              commit('update', { module: 'reportingIntrosFeedback', key: 'comments', value: data }, { root: true });
              window.mixpanel.track('Intros Feedback — Loaded Comments', { numComments: data.total_count });
            },
            complete: () => { commit('updateFetchStatus', { key: 'comments', value: true }); },
          },
        );
      });
    },

    changePage({ dispatch }, { pageNum }) {
      const kaminariPageNum = pageNum + 1; // Kaminari doesn't zero index
      dispatch('fetchComments', { pageNum: kaminariPageNum });
      window.mixpanel.track('Intros Feedback — Changed Page', { pageNum });
    },

    applySettingsAndFetchData({ commit, dispatch }, { settings, isInitialLoad }) {
      commit('update', { module: 'reportingIntrosFeedback', key: 'settings', value: settings }, { root: true });
      setLocalStorage('introsFeedbackSettings', JSON.stringify(settings));
      if (!isInitialLoad) window.mixpanel.track('Intros Feedback — Saved Settings', { settings: JSON.stringify(settings) });
      dispatch('fetchStats');
      dispatch('fetchComments', { pageNum: 1 });
    },
  },
};
