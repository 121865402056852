const defaults = {
  visible: false,
  subcomponent: null,
  size: null, // Options are null (the smallest), "md", "lg", "xl", "xxl", "xxxl", "of-v" (TODO: of-v is for overflowing content, rename this property)
  onClose: null,
  dismissable: true,
  confirmOnCancel: false,
  data: null,
  cancelAction: null,
  cancelMutation: null,
  cancelArgs: null,
  scrollToTopOnChange: false,
  noPadding: false,
  isMedia: false,
};

export default {
  updateModal(state, value) {
    state.modal = Object.assign({}, defaults, value);
  },

  updateExistingModal(state, value) {
    // Use this to update values to an already open modal
    state.modal = { ...state.modal, ...value };
  },

  closeModal(state) {
    state.modal = defaults;
  },

  updateModalData(state, value) {
    // We should deprecate this in favor of the more generic `updateExistingModal`
    state.modal = {
      ...state.modal,
      data: Object.assign({}, state.modal.data, value),
    };
  },

  // Specific Modals

  openVimeoModal(state, {
 id, title, autoPlay, cancelMutation, cancelArgs,
}) {
    state.modal = {
      ...defaults,
      visible: true,
      subcomponent: 'donut-vimeo-modal',
      size: 'xl',
      isMedia: true,
      cancelMutation,
      cancelArgs,
      data: { id, title, autoPlay },
    };
  },
};
