import Network from 'lib/api/network';

export default {
  namespaced: true,

  state() {
    return {
      identity: null,
      canConnectGreenhouse: false,
      apiCredentials: {
        url: null,
        apiKey: null,
      },
      validApiKey: false,
      loading: false,
      validationErrors: null,
      defaultAtsTagIds: [],
    };
  },

  getters: {
    permissionsList() {
      return [
        {
          name: 'Applications',
          fields: {
            list_applications: 'Get: List applications',
            get_application: 'Get: Retrieve application',
          },
        },
        {
          name: 'Candidates',
          fields: {
            list_candidates: 'Get: List candidates',
            get_candidate: 'Get: Retrieve candidate',
          },
        },
        {
          name: 'Jobs',
          fields: {
            get_job: 'Get: Retrieve job',
            list_jobs: 'Get: List jobs',
            get_hiring_team: 'Get: Get hiring team',
          },
        },
        {
          name: 'Offers',
          fields: {
            list_offers: 'Get: List offers',
            get_offer: 'Get: Retrieve offer',
            get_application_offers: 'Get: List application\'s offers',
            get_application_current_offer: 'Get: Retrieve application\'s current offer',
          },
        },
        {
          name: 'Users',
          fields: {
            get_user: 'Get: Retrieve user',
          },
        },
      ];
    },
  },

  mutations: {
    update(state, { key, value }) {
      state[key] = value;
    },
  },

  actions: {
    track(_context, { eventName, properties }) {
      const fullEventName = `ATS Connect: ${eventName}`;
      const fullProperties = properties || {};
      window.mixpanel.track(fullEventName, fullProperties);
      window.FS.event(fullEventName); // ignore fullProperties
      window.Intercom('trackEvent', fullEventName);
    },

    openConnectModal({ commit, dispatch }) {
      dispatch('track', {
        eventName: 'Open Connect Modal',
      });

      commit('updateModal', {
        visible: true,
        subcomponent: 'ats-connect-modals-auth-url',
      }, { root: true });
    },

    createDirectIntegrationIdentity({ state, commit, dispatch }, modalArgs) {
      dispatch('track', {
        eventName: 'Create Direct Integration Identity',
      });

      state.loading = true;
      const params = {
        direct_integration_identity: {
          integration_name: 'greenhouse',
          api_key: state.apiCredentials.apiKey,
        },
      };
      return new Promise((resolve, reject) => {
        Network.post(
          '/direct_integration_identities',
          {
            ...params,
          },
          {
            success: (response) => {
              state.identity = response.direct_integration_identity;
              commit('updateModal', modalArgs, { root: true });
              dispatch('track', {
                eventName: 'Create Direct Integration Identity',
                properties: {
                  identityId: response.direct_integration_identity.id,
                },
              });
              resolve(response.direct_integration_identity);
            },
            error: (response) => {
              commit('update', { key: 'errors', value: response.errors });
              dispatch('track', {
                eventName: 'Create Direct Integration Identity Failed',
                properties: {
                  errors: response.errors,
                },
              });
              reject();
            },
            complete: () => {
              state.loading = false;
            },
          },
        );
      });
    },

    resetValidationErrors({ state, dispatch }) {
      state.validationErrors = null;
      dispatch('validateApiKey');
    },

    validateApiKey({ state, dispatch }) {
      state.validApiKey = false;
      dispatch('track', {
        eventName: 'Api Key Validation Started',
      });
      return new Promise((resolve, reject) => {
        Network.get(`/direct_integration_identities/${state.identity.id}/validate`, {
          success: (response) => {
            state.identity = response.direct_integration_identity;
            if (['validated', 'active'].includes(response.direct_integration_identity.state)) {
              state.validApiKey = true;
              dispatch('track', {
                eventName: 'Api Key Validation Succeeded',
              });
            }
            resolve(response.direct_integration_identity.state);
          },
          error: () => {
            state.validApiKey = false;
            dispatch('track', {
              eventName: 'Api Key Validation Failed',
            });
            reject();
          },
        });
      });
    },

    pollForIdentityState({ state, dispatch }, statesToPollFor = ['active']) {
      if (!state.identity) {
        return;
      }

      dispatch('track', {
        eventName: 'Poll For Identity State',
      });

      state.loading = true;
      let timeoutId;
      const poll = () => {
        Network.get(`/direct_integration_identities/${state.identity.id}`, {
          success: (response) => {
            state.identity = response.direct_integration_identity;
            if (statesToPollFor.includes(response.direct_integration_identity.state)) {
              state.validApiKey = true;
              state.loading = false;
              dispatch('track', {
                eventName: 'Poll For Identity State',
                properties: {
                  status: response.direct_integration_identity.state,
                },
              });
            } else if (response.direct_integration_identity.state === 'error') {
              state.loading = false;
              state.validationErrors = response.validation_errors;
              state.validApiKey = false;
            } else {
              timeoutId = setTimeout(poll, 5000);
            }
          },
          error: () => {
            dispatch('track', {
              eventName: 'Poll For Identity State',
              properties: {
                status: 'error',
              },
            });
            state.loading = false;
          },
        });
      };
      poll();
      return () => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
      };
    },

    navigateToEnrollmentPage() {
      window.location.href = '/onboarding/tags/enrollment';
    },
  },
};
