import API from 'lib/api/api';
import Network from 'lib/api/network';

export default {
  recordUsedPairingAnnouncement({ commit }) {
    commit('recordUsedPairingAnnouncement');
    API.UserDetails.recordProgress('used_pairing_announcement');
  },

  updateCoffeeMeeting({ commit, dispatch }, { coffeeMeeting, args }) {
    const originalState = coffeeMeeting.state;
    return new Promise((resolve, reject) => {
      API.CoffeeMeetings.update(coffeeMeeting, args, {
        success: (data) => {
          commit('updateCoffeeMeeting', { coffeeMeeting: data, originalState });
          dispatch('setSuccessToast', "Great! We've updated your status!");
          resolve(true);
        },
        error: (error) => {
          dispatch('setErrorToast');
          resolve(false);
        },
      });
    });
  },

  destroyAllGhosts({ commit, dispatch }, { configId, page }) {
    commit('update', { key: 'formSubmitted', value: true });
    return new Promise((resolve, reject) => {
      API.CoffeeConfigurations.destroyAllGhosts(
        configId,
        { page },
        {
          success: (data) => {
            commit('updateUserResultsUsers', data.pagedUserResults);
            commit('updateUserResultsGhostCount', data.ghostCount);
            dispatch('setSuccessToast', 'Success! Previously ghosted members will begin receiving Intros again.');
            resolve(true);
          },
          error: (error) => {
            dispatch('setErrorToast');
            resolve(false);
          },
          complete: () => {
            commit('closeModal');
            commit('update', { key: 'formSubmitted', value: false });
          },
        },
      );
    });
  },

  getPeopleMet({ commit }) {
    return new Promise((resolve, reject) => {
      API.CoffeeConfigurations.getPeopleMet({
        success: (data) => {
          commit('update', { key: 'peopleMet', value: data.peopleMet });
          commit('update', { key: 'teamSize', value: data.teamSize });
          resolve(true);
        },
        error: (error) => {
          resolve(false);
        },
      });
    });
  },

  updateConfigPairingType({ state, getters, commit, dispatch }, value) {
    const config = state.coffeeConfiguration;
    const canAccessAllFeatures = config.service_account.paidFeatureAccess || config.claiming_allowed;
    if (!(canAccessAllFeatures || value === 'standard')) {
      const source = (value === 'lottery') ? 'pairing-lottery' : 'pairing-category';
      dispatch('openChannelProgramsPlanUpgradeModal', {
        trialAllowed: config.service_account.paidCoffeeTrialAllowed,
        data: {
          featureTitleText: `use the ${getters.pairingOptions.selected.title} intro type`,
          source,
          featureInfo: {
            programKey: 'intros',
            feature: 'intros-advanced-settings',
          },
        },
      });
    } else {
      commit('updateCoffeeConfiguration', { key: 'pairing_type', value });
      if (value === 'lottery' || value === 'standard') {
        commit('updateCoffeeConfiguration', { key: 'enforce_group_size', value: false });
      }
    }
  },

  sendPickCategoryGroupMessages({ commit, dispatch }, { coffeeConfigurationId }) {
    commit('update', { key: 'formSubmitted', value: true });
    Network.post(
      `/coffee/${coffeeConfigurationId}/remind_users_to_pick_category_options`,
      {},
      {
        success: (data) => {
          commit('closeModal');
          let text;
          if (data.num_users_messaged === 1) {
            text = 'Success! A message has been sent to one participant.';
          } else {
            text = `Success! Messages have been sent to ${data.num_users_messaged} participants.`;
          }
          commit('update', { key: 'category_group_message_sent_text', value: text });
          dispatch('setSuccessToast', text);
        },
        error: () => {
          dispatch('setErrorToast');
        },
        complete: () => {
          commit('update', { key: 'formSubmitted', value: false });
        },
      },
    );
  },
};
